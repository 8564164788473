import React from 'react'
import PropTypes from 'prop-types'
import { marked } from 'marked'
import { graphql } from 'gatsby'

import Link from '../components/Link'
import FileTypes from '../components/Types'
import ExamplesSingle from '../components/ExamplesSingle'
import TopCard from '../components/TopCard'
import SEO from '../components/SEO'
import GeneralUsps from '../components/GeneralUsps'
import DealCTA from '../components/DealCTA'
import Quote from '../components/Quote'

import { getProductID } from '../utils/helpers'
import withContext from '../lib/withContext'

// Language
import fullCollectionPrice from '../lang/fullCollectionPrice'

const {
  unit,
  discountedPrice
} = fullCollectionPrice

class Single extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      colors: ['sun', 'sea', 'rose'],
      products: []
    }
  }

  componentDidMount() {
    const { data, products } = this.props
    const pageName = data.allContentfulIconSet.edges[0].node.slug

    this.setState({
      pageName,
      products: products.products || []
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, products } = this.props
    const { edges } = data.allContentfulIconSet
    const { pageName } = this.state

    if (prevState.pageName !== pageName) {
      this.setRelevantIconSetColors(
        edges[0].node.relatedSets,
        edges[0].node.relatedSetsColors
      )
    } else if (prevProps === this.props) {
      return
    }

    this.setState({
      products: products.products || []
    })
  }

  setRelevantIconSetColors = (sets, colors) => {
    if (!sets || !colors) { return }

    if (sets.length > colors.length) {
      const newColors = [...colors, 'sea', 'sun', 'rose']
        .reduce((previous, next) => (
          previous.includes(next) ? previous : [...previous, next]
        ), [])

      this.setState({
        colors: newColors
      })
    } else {
      this.setState({
        colors
      })
    }
  }

  render() {
    const {
      addVariantToCart,
      lineItems,
      data,
      fullCollectionId
    } = this.props

    const {
      colors,
      pageName,
      products
    } = this.state

    const { edges } = data.allContentfulIconSet

    const metaData = {
      title: `Categories - ${edges[0].node.title}`,
      image: `https://jollycons.com/meta/${edges[0].node.slug}/meta-img-${edges[0].node.slug}.png`
    }

    return (
      <div>
        <SEO metaData={metaData} />
        <main className="single animated fadeInPage">
          <div className="container-md padding-xxl-top">
            <div className="grid product-item">
              <div className="col-6">
                <Link to="/categories" className="link-back">
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                  >
                    <path
                      fill="#9FA3B6"
                      fillRule="evenodd"
                      d="M6.98460668,2.54971844 L9.98460668,2.54971844 C11.0891762,2.54971844 11.9846067,3.44514894 11.9846067,4.5497184 C11.9846067,5.6542879 11.0891762,6.5497184 9.98460668,6.5497184 L6.98460668,6.5497184 L6.98460668,7.6065291 C6.98460668,8.1588139 6.53689143,8.6065291 5.98460668,8.6065291 C5.77618371,8.6065291 5.57296924,8.541406 5.40336849,8.4202626 L1.12383354,5.3634519 C0.674420956,5.0424429 0.570329275,4.4178928 0.891338265,3.96848025 C0.955477923,3.87868473 1.03403802,3.80012463 1.12383354,3.73598497 L5.40336849,0.67917429 C5.85278108,0.3581653 6.47733116,0.46225699 6.79834015,0.91166957 C6.91948355,1.08127032 6.98460668,1.28448479 6.98460668,1.49290777 L6.98460668,2.54971844 Z"
                    />
                  </svg>
                  Back to categories
                </Link>
                <div className="product-item-preview">
                  <img
                    src={require(`../images/categories/${edges[0].node.slug}/hero-${edges[0].node.slug}@2x.png`)}
                    alt={edges[0].node.slug}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="product-item-info">
                  <h2>{ edges[0].node.title }</h2>
                  <h4 className="price">
                    &#36;
                    {edges[0].node.price}
                  </h4>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={
                      {
                        __html: marked(edges[0].node.description.description)
                      }
                    }
                  />

                  <FileTypes size="small" />

                  <div className="license-container">
                    <h4>License</h4>
                    <p>
                      Standard License Agreement
                      <Link to="/license" className="text-link storm">Learn More</Link>
                    </p>
                  </div>

                  <div className="actions">
                    <button
                      className="btn primary lg"
                      onClick={() => addVariantToCart(getProductID(pageName, products))}
                      type="button"
                    >
                      Buy Category
                    </button>

                    <button
                      className="btn secondary border lg"
                      onClick={() => addVariantToCart(fullCollectionId)}
                      type="button"
                    >
                      Buy Jollycons
                      <span className="price">
                        {unit}
                        <strong>{discountedPrice}</strong>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-lg wide padding-xxl-top">
            <div className="grid details">
              <div className="details-text">
                <h3>Gorgeous details</h3>
                <p>{`Zooming in on each single icon shows how its details and how it follows predefined guidelines, creating consistency throughout all Jollycons icons and the ${edges[0].node.title} set in particular.`}</p>
              </div>
              <img src={require(`../images/categories/${edges[0].node.slug}/detail-${edges[0].node.slug}@2x.png`)} alt="details" />
            </div>
          </div>

          <GeneralUsps />

          <ExamplesSingle name={edges[0].node.slug} />

          <div className="container-md padding-xxl-top">
            <div className="grid quote">
              <Quote
                text="The crisp and detailed icons are a great addition to any designers toolkit. I especially love them for fun a friendly projects."
                author="Jeff van Steijn"
                authorTitle="Designer, Yummygum"
              />
            </div>
          </div>


          {
            edges[0].node.relatedSets && (
              <div className="container-md wide padding-xxl-top">
                <div className="top-selling single">
                  <h3>You might also like..</h3>
                  <div className="related-sets-wrapper">
                    {
                      edges[0].node.relatedSets.map((cardData, idx) => (
                        <TopCard
                          {...cardData}
                          {...{ lineItems }}
                          id={
                            !cardData.comingSoon
                              && getProductID(cardData.slug, products)
                          }
                          colorType={colors[idx]}
                          comingSoon={cardData.comingSoon}
                          key={cardData.id}
                          price={cardData.price}
                          addVariantToCart={addVariantToCart}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
            )
          }

          <DealCTA
            type="dark"
            addVariantToCart={addVariantToCart}
            fullCollectionId={fullCollectionId}
          />
        </main>
      </div>
    )
  }
}

Single.propTypes = {
  addVariantToCart: PropTypes.func,
  data: PropTypes.any,
  lineItems: PropTypes.array,
  products: PropTypes.any,
  fullCollectionId: PropTypes.string.isRequired
}

export default withContext(Single)

export const iconSetQuery = graphql`
  query rightIconSet($slug: String!) {
    allContentfulIconSet(filter: {
      slug: {
        eq: $slug
      }
    }) {
      edges {
        node {
          id
          metaTitle
          metaDescription
          slug
          title
          price
          description {
            id
            description
          }
          relatedSets {
            id
            title
            price
            slug,
            comingSoon
          }
          relatedSetsColors
        }
      }
    }
  }
`
