import React from 'react'
import PropTypes from 'prop-types'

const ExamplesSingle = ({ name }) => (
  <div className={`container-lg examples ${name === 'pride' ? 'pride' : ''}`}>
    <div className="examples-row">
      <div className="example small leaf">
        <img src={require(`../images/categories/${name}/usage-example-leaf-${name}@2x.png`)} alt="" />
      </div>
      <div className="example sea">
        <img src={require(`../images/categories/${name}/usage-example-sea-${name}@2x.png`)} alt="" />
      </div>
    </div>

    <div className="examples-row">
      <div className="example sun">
        <img src={require(`../images/categories/${name}/usage-example-sun-${name}@2x.png`)} alt="" />
      </div>
      <div className="example small rose">
        <img src={require(`../images/categories/${name}/usage-example-rose-${name}@2x.png`)} alt="" />
      </div>
    </div>
  </div>
)

ExamplesSingle.propTypes = {
  name: PropTypes.string
}

export default ExamplesSingle
