import React from 'react'
import PropTypes from 'prop-types'

const Quote = ({
  text,
  author,
  authorTitle
}) => (
  <div className="quote">
    <div className="bg-grid" />
    <div className="quote-content">
      <p>{ text }</p>
      <h4>
        {author}
        {' — '}
        {authorTitle}
      </h4>
    </div>
  </div>
)

Quote.propTypes = {
  author: PropTypes.string,
  authorTitle: PropTypes.string,
  text: PropTypes.string
}

export default Quote
