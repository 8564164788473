import React from 'react'
import PropTypes from 'prop-types'

const FileTypes = ({ size, inModal }) => (
  <div className={`files ${size} ${inModal ? 'in-modal' : ''}`}>
    <div className="file">
      <img src={require(`../images/files/file-type-png-${size}.svg`)} alt="png illustration" />
      <p className="small">.png</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-svg-${size}.svg`)} alt="svg illustration" />
      <p className="small">.svg</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-pdf-${size}.svg`)} alt="pdf illustration" />
      <p className="small">.pdf</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-sketch-${size}.svg`)} alt="Sketch illustration" />
      <p className="small">.sketch</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-adobexd-${size}.svg`)} alt="Adobe XD illustration" />
      <p className="small">.xd</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-figma-${size}.svg`)} alt="Figma illustration" />
      <p className="small">.fig</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-iconjar-${size}.svg`)} alt="Iconjar illustration" />
      <p className="small">.iconjar</p>
    </div>
    <div className="file">
      <img src={require(`../images/files/file-type-ai-${size}.svg`)} alt="Illustrator illustration" />
      <p className="small">.ai</p>
    </div>
  </div>
)

FileTypes.propTypes = {
  inModal: PropTypes.bool,
  size: PropTypes.string
}

export default FileTypes
